* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

body {
	background: $brand-light;
	color: $brand-dark;
	line-height: 1.5em;
	font-size: 16px;
}

	@media screen and (min-width: $screen-md-min) {
		body {
			font-size: 18px;
		}
	}

html, button, input, select, textarea,
.pure-g [class *= "pure-u"] {
    font-family: $sans-serif
}

figure {margin: 0;}
.pure-img {display: inline-block;}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $brand-bright;
	font-family: $sans-serif;
	font-weight: normal;
	line-height: 1.4;
	margin: 0;
}

	@media screen and (min-width: $screen-lg-min) {
		h1 {font-size: 2em;}
	}

	h1 a, h2 a, h3 a, h4 a, h5 a, h6 a,
	h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover,
	h1 a:focus, h2 a:focus, h3 a:focus, h4 a:focus, h5 a:focus, h6 a:focus {
		color: inherit;
		text-decoration: none;
	}

	h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover,
	h1 a:focus, h2 a:focus, h3 a:focus, h4 a:focus, h5 a:focus, h6 a:focus {
		color: $brand-dark;
	}

.text-left {text-align: left;}
.text-center {text-align: center;}
.text-right {text-align: right;}
	
a {
	color: $brand-bright;
	text-decoration: underline;
	@extend .transitions;
}

	a:hover,
	a:focus {
		color: $brand-dark;
		text-decoration: underline;
	}

blockquote {
	color: $brand-bright;
	font-family: $sans-serif;
	margin: 0;
	font-style: italic;

	p {margin: 0;}
}

	@media screen and (min-width: $screen-md-min) {
		blockquote {
			line-height: 1.6;			
		}
	}