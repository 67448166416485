.l-box {padding: $l-box / 1.5;}
.l-box-half {padding: $l-box / 3;}

	@media screen and (min-width: $screen-md-min) {
		.l-box {padding: $l-box;}
		.l-box-half,
		.l-box-half-md {padding: $l-box / 2;}
	}

	@media screen and (min-width: $screen-lg-min) {
		.l-box {padding: $l-box * 1.5;}
		.l-box-half,
		.l-box-half-md,
		.l-box-half-lg {padding: $l-box * 0.75;}
	}

	@media screen and (min-width: $screen-xl-min) {
		.l-box {padding: $l-box * 2;}
		.l-box-half,
		.l-box-half-md,
		.l-box-half-lg,
		.l-box-half-xl {padding: $l-box;}
	}

.l-box>*:first-child {margin-top: 0;}
.l-box>*:last-child {margin-bottom: 0;}

.l-box-half>*:first-child {margin-top: 0;}
.l-box-half>*:last-child {margin-bottom: 0;}

.l-box.no-sides,
.l-box-half.no-sides {padding-left: 0; padding-right: 0;}

.l-box.no-left,
.l-box-half.no-left {padding-left: 0;}

.l-box.no-right,
.l-box-half.no-right {padding-right: 0;}

.l-box.no-top,
.l-box-half.no-top {padding-top: 0;}

.l-box.no-bottom,
.l-box-half.no-bottom {padding-bottom: 0;}

