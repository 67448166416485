.latest-news {
	background-color: $brand-screen;
	background-image: url(../images/news-background.svg);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 110%;
}

	@media (min-width: $screen-md-min) {
		.latest-news {
			background-position: center right;
			background-size: 75%;
		}
	}

.ftr {
	background-color: #FFF;
	color: $brand-dark;

	.main-menu {
		background-color: #FFF;

		li {
			display: inline-block;
			width: auto;
		}

		a {
			color: $brand-dark;
		}
	}

	span {
		padding-left: 1em;
		display: block;
	}
}

	@media (min-width: $screen-md-min) {
		.ftr span {display: inline-block;}
	}

.tomoro a {
	color: #CCC;
	text-decoration: none;
}
.header_logo_width{
	width: 700px;
}