.pure-button {
	background-color: $brand-dark;
	border-radius: 3px;
	border: none;
	color: #FFF;
	font-size: 1.2em;
	font-family: $serif;
	box-shadow: none;
	@extend .transitions;
}

	.pure-button:hover,
	.pure-button:focus {
		background-color: $brand-bright;
	}