// Padding
$l-box:							1.1em;

// Colors
$brand-bright:        #00b2a9;
$brand-dark:      #0d5257;
$brand-light:       #FFF;
$brand-screen:      #E6F7F6;


// Pure CSS Breakpoints
$screen-sm-min:			568px;
$screen-md-min:			768px;
$screen-lg-min:			1024px;
$screen-xl-min:			1280px;

// Font Families 
$sans-serif:				"effra", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
$serif:						Georgia, serif;

// WP Default Classes
$line-height-computed:	1;
$thumbnail-caption-padding:	20px;

// Transitions
.transitions {
	-webkit-transition: all .2s ease-out;
	-moz-transition: all .2s ease-out;
	-ms-transition: all .2s ease-out;
	-o-transition: all .2s ease-out;
	transition: all .2s ease-out;	
}