.feature {
	background-color: $brand-dark;
	
	h1 {
		color: #FFF;
	}

	@media screen and (min-width: $screen-lg-min) {
		h1 {
			font-size: 3em;
		}
	}
}

	.background-cover {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}

.card-people {
	padding-top: 75px;

	h2 {padding-bottom: 0.5em;}
}

.l-box-half .card-people {margin-bottom: 30px;}

	@media screen and (min-width: $screen-lg-min) {
		.card-people {
			padding-top: 100px;
		}
	}

.card-holder {}

.card {
	background: #FFF;
	border-bottom: 3px solid $brand-bright;
	cursor: pointer;
	position: relative;
	box-shadow: 0 0 10px 0 rgba(145,151,174,.5);
	@extend .transitions;

	h2 {color: $brand-dark;}

	.bigclick {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

	figure {
		display: block;
		width: 100%;
		height: 90px;	
		position: relative;

		.avatar {
			width: 50px;
			height: 50px;
			top: auto;
			left: auto;
			bottom: -12px;
			right: 10px;			
		}

		@media screen and (min-width: $screen-lg-min) {
			.avatar {
				width: 100px;
				height: 100px;
			}
		}		
	}

		@media screen and (min-width: $screen-md-min) {
			figure {
				height: 150px;
			}
		}	

	.avatar {
		background-color: #FFF;
		width: 100px;
		height: 100px;
		position: absolute;
		top: -25px;
		right: auto;
		bottom: auto;
		left: 50%;
		margin-left: -50px;
		border-radius: 50%;
		border: 2px solid #FFF;
	}

		@media screen and (min-width: $screen-lg-min) {
			.avatar {
				width: 150px;
				height: 150px;
				top: -50px;
				margin-left: -75px;
			}
		}	

	.border-bottom {
		border-bottom: 1px solid $brand-screen;
	}
}

	.card:hover,
	.card:focus {
		box-shadow: 0 0 10px 5px rgba(33,55,85,.5);
	}

.circle {
	border-radius: 50%;
	overflow: hidden;
	width: 100%;
	height: 0;
	padding-bottom: 100%;

	img {float: left;}
}

.entry-content {

	blockquote {
		font-size: 1.2em;
	}
}
