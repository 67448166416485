.widget {

	h3 {
		color: $brand-dark;
		margin-top: 2em;
		padding-left: 10px;
		padding-right: 10px;
	}

	ul {
		list-style: none;
		padding: 0;
	}

	li a {
		display: block;
		padding: 10px;
		text-decoration: none;
		border-bottom: 1px solid #FFF;
	}

	li:first-of-type {
		border-top: 1px solid #FFF;
	}

	li a:hover,
	li a:focus {
		background-color: #FFF;
	}

	li.current_page_item a {
		color: $brand-dark;
	}
}

	.widget:first-of-type h3 {
		margin-top: 0;
	}

