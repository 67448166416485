.hdr {
	background-color: $brand-dark;
	color: #FFF;

	p {
		text-align: right;
		clear: both;
		padding-top: 0;
		margin-top: 0;
	}
}

@media (min-width: $screen-md-min) {
	.hdr p,
	.main-menu li {
		font-size: 1.2em;
		padding-top: 0;
	}
}	

.hdr,
.ftr {
	.logo {
		width: 165px;
		height: auto;
	}

		@media (min-width: $screen-md-min) {
			.logo {
				width: 250px;
				height: auto;
				float: left;
				padding: 0;
			}
		}
		
		@media (min-width: $screen-lg-min) {
			.logo {
				width: 350px;
			}
		}

	.main-menu {
		width: 100%;

		ul {
		  margin: 0;
		  padding: 0;
		  width: 100%;
		  display: block;
		  list-style: none;
		}

		li {
			width: 100%;
			display: block;
		}

		a {
			color: #FFF;
			font-family: $sans-serif;			
			text-decoration: none;
			display: block;
			padding: 0 1em 1em 1em;
			padding-right: 0;
			margin-left: 1em;
		}

		.active a {color: $brand-bright;}

		a:hover,
		a:focus {
			color: $brand-bright;
		}
	}

			@media (min-width: $screen-md-min) {
				.main-menu li {
					display: inline-block;
					width: auto;
				}
			}

	.nav-toggle {
		background-color: $brand-bright;
		border-radius: 3px;
		color: #FFF;
		float: right;
		padding: 5px 10px 5px 30px;
		position: relative;
		text-decoration: none;
	}

	.nav-toggle:before {
	  content: "";
	  position: absolute;
	  left: 10px;
	  top: 50%;
	  margin-top: -5px;
	  width: 14px;
	  height: 2px;
	  background: $brand-dark;
	  box-shadow: 
	    0 4px 0 0 $brand-dark,
	    0 8px 0 0 $brand-dark;
	}
}

/*! responsive-nav.js 1.0.39 by @viljamis */

.js .nav-collapse {
  clip: rect(0 0 0 0);
  max-height: 0;
  position: absolute;
  display: block;
  overflow: hidden;
  zoom: 1;
}

.nav-collapse.opened {
  max-height: 9999px;
}

.nav-toggle {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

@media screen and (min-width: $screen-md-min) {
  .js .nav-collapse {
    position: relative;
  }
  .js .nav-collapse.closed {
    max-height: none;
  }
  .nav-toggle {
    display: none;
  }
}
